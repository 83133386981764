var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('TopBar',{attrs:{"type":2,"pageTitle":_vm.content ? _vm.content.exam_info.exam_name : ''}}),_c('div',{staticClass:"sheet",on:{"click":function($event){_vm.sheetModal = true}}}),_c('div',{staticClass:"tip-bg"},[(_vm.content)?_c('div',{staticClass:"tip"},[_c('p',{staticClass:"countdown"},[_vm._v(" 考试倒计时："),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.limitTime))])]),_c('p',{staticClass:"score"},[_vm._v(" 总分"+_vm._s(_vm.content.exam_info.score)+"分，"+_vm._s(_vm.content.exam_info.pass)+"分及格 ")])]):_vm._e()]),_vm._l((_vm.content.questions),function(item){return _c('ul',{key:item.type,staticClass:"topic"},[(item.question_list.length > 0)?_c('li',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.type_name)+" "),_c('span',{staticClass:"sub-title"},[_vm._v("（共"+_vm._s(item.number)+"题，合计"+_vm._s(item.total_score)+"分）")])]),(item.type != 4)?_c('ul',{staticClass:"category"},_vm._l((item.question_list),function(timu,timuIndex){return _c('li',{key:timu.id},[_c('div',{staticClass:"question-title"},[_vm._v(" "+_vm._s(timuIndex + 1)+"、"+_vm._s(timu.question)+" ")]),_vm._l((timu.selectdata),function(data){return _c('label',{key:data.key,staticClass:"question-data",class:{
              active: _vm.selectedDta[
                `question_${item.type}_${timu.id}`
              ].includes(data.key),
            }},[(item.type != 2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDta[`question_${item.type}_${timu.id}`]),expression:"selectedDta[`question_${item.type}_${timu.id}`]"}],attrs:{"type":"radio","name":'timu_' + timu.id},domProps:{"value":data.key,"checked":_vm._q(_vm.selectedDta[`question_${item.type}_${timu.id}`],data.key)},on:{"change":function($event){return _vm.$set(_vm.selectedDta, `question_${item.type}_${timu.id}`, data.key)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDta[`question_${item.type}_${timu.id}`]),expression:"selectedDta[`question_${item.type}_${timu.id}`]"}],attrs:{"type":"checkbox","name":'timu_' + timu.id},domProps:{"value":data.key,"checked":Array.isArray(_vm.selectedDta[`question_${item.type}_${timu.id}`])?_vm._i(_vm.selectedDta[`question_${item.type}_${timu.id}`],data.key)>-1:(_vm.selectedDta[`question_${item.type}_${timu.id}`])},on:{"change":function($event){var $$a=_vm.selectedDta[`question_${item.type}_${timu.id}`],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=data.key,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selectedDta, `question_${item.type}_${timu.id}`, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selectedDta, `question_${item.type}_${timu.id}`, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selectedDta, `question_${item.type}_${timu.id}`, $$c)}}}}),_c('span',[_vm._v(_vm._s(data.key)+" "+_vm._s(data.value))])])})],2)}),0):_c('ul',{staticClass:"category"},_vm._l((item.question_list),function(timu,timuIndex){return _c('li',{key:timu.id},[_c('div',{staticClass:"question-title"},[_vm._v(" "+_vm._s(timuIndex + 1)+"、"+_vm._s(timu.question)+" ")]),_vm._l((timu.answer_count),function(data,index){return _c('label',{key:data.key,staticClass:"question-data",class:{
              active: _vm.selectedDta[
                `question_${item.type}_${timu.id}`
              ].includes(data.key),
            }},[_c('span',[_vm._v("填空"+_vm._s(index + 1))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDta[`question_${item.type}_${timu.id}`][index]),expression:"selectedDta[`question_${item.type}_${timu.id}`][index]"}],staticClass:"border",attrs:{"type":"text","value":"","name":'timu_'+timu.id},domProps:{"value":(_vm.selectedDta[`question_${item.type}_${timu.id}`][index])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedDta[`question_${item.type}_${timu.id}`], index, $event.target.value)}}})])})],2)}),0)]):_vm._e()])}),_c('button',{staticClass:"sub-btn",on:{"click":_vm.subBtn}},[_vm._v("提交")]),(_vm.sheetModal)?_c('div',{staticClass:"sheet-modal"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"content-title"},[_vm._v(" 答题卡"),_c('span',{staticClass:"close",on:{"click":function($event){_vm.sheetModal = false}}})]),_vm._l((_vm.content.questions),function(item){return _c('ul',{key:item.type_name,staticClass:"answer-sheet"},[(item.question_list.length > 0)?_c('li',{staticClass:"category"},[_c('p',{staticClass:"category-title"},[_vm._v(_vm._s(item.type_name))]),_vm._l((item.question_list),function(num,index){return _c('span',{key:num.id,staticClass:"test-num-box",class:_vm.selectedDta[`question_${item.type}_${num.id}`] != ''
                ? 'active'
                : ''},[_vm._v(_vm._s(index + 1))])})],2):_vm._e()])})],2)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }